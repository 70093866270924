function About() {
  return (
    <div className="body-text">
      <p>
        OBFUSCATE! is a Berlin-based site-specific live performance series in the form of stand-up comedy shows.
      </p>
      <p>
        By transporting stand-up comedy from the backrooms and basements of the city’s bars into a gallery setting, artists Josie Parkinson and Matthew Liam Healy invite their audience to consider both the impact of context on live stand-up comedy, as well as the social role stand-up comedy plays.
      </p>
      <p>
        Does a joke told in a gallery become art? What is the minimum requirement for a comedy show - and what social contracts need to be forged in order to distinguish it from any other kind of interaction with a crowd? More importantly: can it still be funny?
      </p>
    </div>
  )
}

export default About;