import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './About';
import Callout from './Callout';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <nav>
          <div className="nav-right">
            <ul>
              <li>
                <Link to="/" className="nav-item">Home</Link>
              </li>
              <li>
                <Link to="/about" className="nav-item">About</Link>
              </li>
            </ul>
          </div>
        </nav>
        <h1 className="header-text">OBFUSCATE!</h1>
        <Routes>
          <Route path="/" element={<Callout />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
