import Spacer from './Spacer';

function Callout() {
  return (
    <div className="body-text">
      <h2>Thursday, April 27th at NEON KUNST</h2>
      <p> Doors 1900, show 2000. <a href="https://neonkunst.home.blog/2023/04/22/27-04-2023-obfucate/">LINK</a>.</p>
      <h3>Description</h3>
      <p>what, in your opinion, makes a comedy show?</p>

      <p>a perfectly-timed punchline? delusions of grandeur? the</p>
      <p>microphone?</p>

      <p>room organisation? front row’s expected consent to interrogation?</p>

      <p>what brought you to berlin?</p>
      <p>do you work in i.t.?</p>
      <p>what category of joke is your life?</p>

      <p>a brick wall? a bit of fun after work? the performer as leader / philosopher / anointed everyman? saying what we’re all thinking?</p>

      <p>heckler E V I S C E R A T E D gig D E S T R O Y E D joke M U R D E R E D</p>

      <p>if a successful comedian performs in a forest and no-one is around to hear them, is that maybe for the best, all things considered?</p>

      <Spacer height={1} color='white' />

      <p>OBFUSCATE! is a live performance which you may or may not wish to consider a comedy show. Comedians and absolute clowns Josie Parkinson and Matthew Healy will be joined by whoever is available to figure out what the audience wants to see, and give it to them. They will also tell some jokes, if the occasion calls for it.</p>

      <h3>OBFUSCATE! is:</h3>

      <p><b>Matthew Liam Healy</b></p>

      <p>Matthew Liam Healy (they/them) is many things: celebrated humour theorist, popular after-dinner speaker, no-time winner of the Berlin New Stand-Up Award, hundreds of tiny mice collaborating to control a person-shaped machine, an artist working predominantly in the medium of failure, a gentle ennui, a̷͈̪̓ ̴̺̯͆p̵͓̃͠o̸͍̓̈r̴̼̾͊ͅṱ̷̔͛e̶̪̽ͅn̵̛͉̏t̸̞͑ ̴̡̘̇͆o̴͊͂͜f̸̧̹̌ ̷̘̺͆͘ţ̸̺̇h̴̞̽ͅĕ̵̻̟̍ ̵̻̰́e̴̘̽̂n̴̨͉͆̔ḓ̶̑-̷̪́͘t̷̼͑͘i̴͉͌͛m̵͙̳͝ê̷̱͜s̵̢̔ and a fool who lies in biographies for attention.</p>

      <p><b>Josie Parkinson</b></p>

      <p>Josie Parkinson (she/her) is a British writer and comedian based in Berlin. Josie is currently developing a one-woman show, Josie Parkinson Is Not A Sex Robot Anymore, tackling **Hard-Hitting Issues** like sex, agency and the underrated sensuality of the pie machine from Chicken Run. Josie also studies film directing at filmArche, sells dumplings on the weekends, and is very tired!</p>

      <Spacer height={48} />
    </div>
  )
}

export default Callout;
