import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
}

const Spacer: React.FC<Props> = ({width, height, color}) => {
  const defaultSize = 'auto';
  const defaultColor = 'transparent';

  const style = {
    width: (width != null) ? width + 'px' : defaultSize,
    height: (height != null) ? height + 'px' : defaultSize,
    backgroundColor: (color != null) ? color : defaultColor,
  };

  return React.createElement('div', { style })
};

export default Spacer;